import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";
export default async function EditCaseDetails(id,details) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "details": details
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  response = await fetch(SERVER_URL + "api/lawsuits/"+id+"/details", requestOptions);
  if (response.status === 200) return true;
  return false;
}
