import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Avatar, Stack } from "@mui/material";
import GetUsers from "../api/GetUsers";
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../App.css";
import { Grid, Box, TextField, Card, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import logo from "../logo.svg";
import "../App.css";

import AddUser from "../api/AddUser";
import { ToastContainer, toast } from "react-toastify";
import SuspendUser from "../api/SuspendUser";
import EmailTextField from "../components/EmailTextField";
import NumberTextField from "../components/NumberTextField";
import { validateEmail, validateNum } from "../helperFunctions/Validation";

export default function OrganisationsUsersPage() {
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [suspendedUser, setSuspendedUser] = useState("");

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderActionButtons(params),
    },
  ];

  const renderActionButtons = (params) => {
    return (
      <Button
        onClick={() => {
          setSuspendedUser(params.row.id);
          handleClickOpen();
        }}
        sx={{ backgroundColor: "#ff0000" }}
        variant="contained"
      >
        Suspend
      </Button>
    );
  };
console.log(users,"usersusersusersusersusers");
  const [openD, setOpenD] = React.useState(false);

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };

  useEffect(() => {
    
    fetchData();
  }, []);

  const fetchData = async () => {
    if (users.length == 0) {
      loadUsers()
    }
  }

  const loadUsers= async () =>
  {
    let users_r = await GetUsers();
      if (users_r) {
        setUsers(users_r);
      } else {
      }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SuspendDialog = () => {
    return (
      <Dialog
        open={openD}
        onClose={handleCloseD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Suspend User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to suspend the user with id of {suspendedUser}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseD}>Cancel</Button>
          <Button
            onClick={() => {
              SuspendUser(suspendedUser).then((e) => {
                if (e.success) {
                  toast.success(e.message);
                  handleCloseD();
                  loadUsers()
                } else {
                  toast.error(e.message);
                  handleCloseD();
                }
              });
            }}
            autoFocus
          >
            Suspend
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ height: "85%", width: "100%" }}>
      <div>
        <ToastContainer />
        <Button
          onClick={(e) => {
            handleClick(e);
          }}
        >
          Add User
        </Button>
      </div>
      <DataGrid
        rows={users}
        columns={columns}
        disableSelectionOnClick
        loading={users.length === 0}
      />
      <SuspendDialog></SuspendDialog>
      <ToastContainer />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <Grid container spacing={2} px={10} sx={{marginBottom:3,marginTop:0.1}}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                  Please enter new user details, the new user will recieve an
                  email with temporary password
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <EmailTextField onChange={setEmail}></EmailTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setName(v.target.value)}
                  label="Name"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <NumberTextField
                  onChange={setMobile}
                  label="Mobile"
                ></NumberTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={() => {
                    if(name!=""&&
                      email!=""&&
                      mobile!=""
                    )
                    {
                      if(validateEmail(email)&&
                        validateNum(mobile)
                      )
                      {
                    console.log(email);
                    AddUser(name, email, mobile).then((r) => {
                      if (r) {
                        toast.info(r.message)
                      }
                      else
                      {
                        toast.error("could not create the user")
                      }
                    });
                  }
                  else
                  {
                    toast.error("please type a valid email and a valid number")
                  }
                }
                  else
                  {
                    toast.error("please fill all fields")
                  }
                  }}
                  variant="contained"
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
        </Dialog>

      {/*<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ width: "100%" }}
      >
        
        <Card elevation={15} sx={{ width: "100%" }}>
          <Box py={8} bgcolor="white">
            <Grid container spacing={2} px={10}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                  Please enter new user details, the new user will recieve an
                  email with temporary password
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <EmailTextField onChange={setEmail}></EmailTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setName(v.target.value)}
                  label="Name"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <NumberTextField
                  onChange={setMobile}
                  label="Mobile"
                ></NumberTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={() => {
                    if(name!=""&&
                      email!=""&&
                      mobile!=""
                    )
                    {
                      if(validateEmail(email)&&
                        validateNum(mobile)
                      )
                      {
                    console.log(email);
                    AddUser(name, email, mobile).then((r) => {
                      if (r) {
                        toast.info(r.message)
                      }
                      else
                      {
                        toast.error("could not create the user")
                      }
                    });
                  }
                  else
                  {
                    toast.error("please type a valid email and a valid number")
                  }
                }
                  else
                  {
                    toast.error("please fill all fields")
                  }
                  }}
                  variant="contained"
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
                </Popover>*/}
    </div>
  );
}
