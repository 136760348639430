import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";
import GetUserInfo from "./GetUserInfo";
export default async function EditUser(name, mobile) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "name": name,
    "mobile": mobile,
  });
  
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  let user = await GetUserInfo();
  if (user) {
  response = await fetch(SERVER_URL + "api/users/"+user.id, requestOptions);
  if (response.status === 200) return true;
  return false;
}}
