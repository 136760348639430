import React, { useEffect, useState,useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Avatar, Stack } from "@mui/material";
import GetUsers from "../api/GetUsers";
import GetClients from "../api/GetClients";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import UpdateClient from "../api/UpdateClient";
import { ToastContainer, toast } from "react-toastify";
import DeleteClient from "../api/DeleteClient";
import Popover from "@mui/material/Popover";
import CircularProgress from "@mui/material/CircularProgress";
import NumberTextField from "../components/NumberTextField"
import Dialog from "@mui/material/Dialog";
import {
  Grid,
  Box,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
} from "@mui/material";
import AddClient from "../api/AddClient";
import CountrySelect from "../components/CountrySelect";
import { validateNum } from "../helperFunctions/Validation";
import { convertBase64 } from "../helperFunctions/Converters";
import UploadClientFile from "../api/UploadClientFile";

export default function ClientsList(props) {
  const [cId, setCId] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [makani, setMakani] = useState("");
  const [tel, setTel] = useState("");
  const [mobile, setMobile] = useState("");
  const [iban, setIban] = useState("");
  const [legalRepresentative, setLegalRepresentative] = useState("");
  const [addClient, setAddClient] = useState(false);
  const [loading, setLoading] = useState(false);
console.log(props,"props");
  const onAddClient = async () => {
    if (
      name != "" &&
      country != "" &&
      state != "" &&
      city != "" &&
      addressLine != "" &&
      makani != "" &&
      tel != "" &&
      mobile != "" &&
      iban != "" &&
      legalRepresentative != ""
    ) {
      if (validateNum(tel) && validateNum(mobile)) {
        setLoading(true);
        let response = await AddClient(
          name,
          country,
          state,
          city,
          addressLine,
          makani,
          tel,
          mobile,
          iban,
          legalRepresentative
        );
        if (response) {
          toast.success("Client added successfully");
        } else {
          toast.error("Client could not be added");
        }
        setLoading(false);
        handleClose();
      } else {
        toast.error("Please type a valid mobile and telephone number");
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  const saveClient = () => {
    if (
      name != "" &&
      country != "" &&
      state != "" &&
      city != "" &&
      addressLine != "" &&
      makani != "" &&
      tel != "" &&
      mobile != "" &&
      iban != "" &&
      legalRepresentative != ""
    ) {
      UpdateClient(
        cId,
        name,
        country,
        state,
        city,
        addressLine,
        makani,
        tel,
        mobile,
        iban,
        legalRepresentative
      ).then((r) => {
        if (r) {
          toast.success("client saved successfully");
          window.location.reload(false);
        } else {
          toast.error("could not save client information");
        }
        handleClose();
      });
    } else {
      toast.error("Please fill all fields");
    }
  };

  const renderActionButtons = (params) => {
    return (
      <Button
        variant="contained"
        onClick={
          (e) => {
            setCId(params.row.id);
            setName(params.row.name);
            setCountry(params.row.country);
            setState(params.row.state);
            setCity(params.row.city);
            setAddressLine(params.row.address_line);
            setMakani(params.row.makani_zip);
            setTel(params.row.tel);
            setMobile(params.row.mobile);
            setIban(params.row.iban);
            setLegalRepresentative(params.row.legal_representative);
            handleClick(e);
          } /*() => /*{
          handleClick()

          UpdateClient(
            params.row.id,
            params.row.name,
            params.row.country,
            params.row.state,
            params.row.city,
            params.row.address_line,
            params.row.makani_zip,
            params.row.tel,
            params.row.mobile,
            params.row.iban,
            params.row.legal_representative
          ).then((r) => {
            if (r) {
              toast.success("client saved successfully");
            } else {
              toast.error("could not save client information");
            }
          });
          console.log(params.row);
        }*/
        }
      >
        show
      </Button>
    );
  };

  const renderDeleteButton = (params) => {
    return (
      <Button
        variant="contained"
        sx={{ backgroundColor: "#ff0000" }}
        onClick={() => {
          DeleteClient(params.row.id).then((r) => {
            if (r) {
              toast.success("client deleted successfully");
              fetchData();
            } else {
              toast.error("could not delete client information");
            }
          });
        }}
      >
        delete
      </Button>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },

    /*{
      field: "country",
      headerName: "Country",
      flex: 1,
      align: "left",
      headerAlign: "left",
     
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "address_line",
      headerName: "Address",
      flex: 1,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "makani_zip",
      headerName: "Makani",
      flex: 1,
      align: "left",
      headerAlign: "left",
      
    },*/
    {
      field: "tel",
      headerName: "Tel",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    /*{
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      align: "left",
      headerAlign: "left",
      
    },
    {
      field: "iban",
      headerName: "IBAN",
      flex: 1,
      align: "left",
      headerAlign: "left",
     
    },*/
    {
      field: "legal_representative",
      headerName: "Legal Representative",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "action1",
      headerName: "Action",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderActionButtons(params),
    },
    {
      field: "action2",
      headerName: "Action",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderDeleteButton(params),
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [clients, setClients] = useState("");
  const [refresh, setRefresh] = useState(false);


  const fetchData = async () =>  {
    //if (clients.length == 0) {
      let clients_r = await GetClients();
      if (clients_r) {
        setClients(clients_r);
      } else {
      }
    //}
  }

  useEffect(() => { 
    fetchData();
  }, [refresh]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAddClient(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const myRefname = useRef(null);
  return (
    <div style={{ height: "85%", width: "100%" }}>
      <ToastContainer />
      <div>
        <Button
          onClick={(e) => {
            setAddClient(true);
            handleClick(e);
          }}
        >
          Add Client
        </Button>
        <Button onClick={() => {
              myRefname.current.click();
            }}>
        Upload
        </Button>
        <Button sx={{ display: "none" }} component="label" ref={myRefname}>    
         <input
         hidden
              type={"file"}
              id={"mediaFile"}
              onChange={(e) => {
                const file = e.target.files[0];
                convertBase64(file)
                  .then((e) => {
                    e = e.replace("data:application/octet-stream;base64,", "");
                    console.log(e);
                    UploadClientFile(e).then(e=>{
                      if(e.success)
                      {
                        toast.success(e.message);
                        fetchData();
                      }
                      else{
                        toast.error(e.message)
                      }
                    });
                  })
                  .then((e) => console.log(e));
              }}
            />
          
        </Button>
        
      </div>
      <DataGrid
        loading={!clients}
        rows={clients}
        columns={columns}
        disableSelectionOnClick
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container spacing={2} px={5} sx={{marginBottom:1,marginTop:0.1}}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                  Please enter new client details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setName(v.target.value)}
                  label="Name"
                  fullWidth
                  value={name}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CountrySelect
                  value={country}
                  onChange={setCountry}
                ></CountrySelect>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(v) => setCity(v.target.value)}
                  label="City"
                  fullWidth
                  value={city}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(v) => setState(v.target.value)}
                  label="State"
                  fullWidth
                  value={state}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setAddressLine(v.target.value)}
                  label="Address Line"
                  fullWidth
                  value={addressLine}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setMakani(v.target.value)}
                  label="Makani"
                  fullWidth
                  value={makani}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberTextField
                onChange={setTel}
                label="Tel"
                value={tel}
                ></NumberTextField>
              </Grid>
              <Grid item xs={12} sm={6}>
              <NumberTextField
                onChange={setMobile}
                label="Mobile"
                value={mobile}
                ></NumberTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setIban(v.target.value)}
                  label="IBAN"
                  fullWidth
                  value={iban}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setLegalRepresentative(v.target.value)}
                  label="Legal Representative"
                  fullWidth
                  value={legalRepresentative}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                {addClient ? (
                  <Button
                    variant="contained"
                    onClick={() => onAddClient()}
                    fullWidth
                  >
                    Add Client
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => saveClient()}
                    fullWidth
                  >
                    Save Changes
                  </Button>
                )}
              </Grid>
              <Grid xs={12} sm={12} sx={{ marginY: 1, textAlign: "center" }}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>

        </Dialog>
      {/*<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ width: "75%" }}
      >
        <Card elevation={15} sx={{ width: "100%" }}>
          <Box py={4} bgcolor="white">
            <Grid container spacing={2} px={5}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                  Please enter new client details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setName(v.target.value)}
                  label="Name"
                  fullWidth
                  value={name}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CountrySelect
                  value={country}
                  onChange={setCountry}
                ></CountrySelect>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(v) => setCity(v.target.value)}
                  label="City"
                  fullWidth
                  value={city}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(v) => setState(v.target.value)}
                  label="State"
                  fullWidth
                  value={state}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setAddressLine(v.target.value)}
                  label="Address Line"
                  fullWidth
                  value={addressLine}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setMakani(v.target.value)}
                  label="Makani"
                  fullWidth
                  value={makani}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberTextField
                onChange={setTel}
                label="Tel"
                value={tel}
                ></NumberTextField>
              </Grid>
              <Grid item xs={12} sm={6}>
              <NumberTextField
                onChange={setMobile}
                label="Mobile"
                value={mobile}
                ></NumberTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setIban(v.target.value)}
                  label="IBAN"
                  fullWidth
                  value={iban}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setLegalRepresentative(v.target.value)}
                  label="Legal Representative"
                  fullWidth
                  value={legalRepresentative}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                {addClient ? (
                  <Button
                    variant="contained"
                    onClick={() => onAddClient()}
                    fullWidth
                  >
                    Add Client
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => saveClient()}
                    fullWidth
                  >
                    Save Changes
                  </Button>
                )}
              </Grid>
              <Grid xs={12} sm={12} sx={{ marginY: 1, textAlign: "center" }}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
          </Box>
        </Card>
                </Popover>*/}
    </div>
  );
}
