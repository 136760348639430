import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Avatar, Stack } from "@mui/material";
import GetOrganizations from "../api/GetOrganizations";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Popover from "@mui/material/Popover";
import SuspendOrg from "../api/SuspendOrg";
import { ToastContainer, toast } from "react-toastify";
import {
  Grid,
  Box,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
} from "@mui/material";

export default function OrganizationsList() {
  const [organizations, setOrganizations] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [popOrgName, setPopOrgName] = useState(null);
  const [popOrgCountry, setPopOrgCountry] = useState(null);
  const [popOrgState, setPopOrgState] = useState(null);
  const [popOrgCity, setPopOrgCity] = useState(null);
  const [popOrgAddressLine, setPopOrgAddressLine] = useState(null);
  const [popOrgMakaniZip, setPopOrgMakaniZip] = useState(null);
  const [popOrgTel, setPopOrgTel] = useState(null);
  const [popOrgMobile, setPopOrgMobile] = useState(null);
  const [popOrgIBAN, setPopOrgIBAN] = useState(null);
  const [popOrgSubscriptionId, setPopOrgSubscriptionId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (organizations.length == 0) {
      loadOrganisations();
    }
  };

  const loadOrganisations = async () => {
    let organizations = await GetOrganizations();
    if (organizations) {
      setOrganizations(organizations);
    } else {
    }
  };

  const [openD, setOpenD] = React.useState(false);
  const [activate, setActivate] = React.useState(false);

  const [suspendedOrg, setSuspendedOrg] = useState("");
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };

  const SuspendDialog = () => {
    return (
      <>
        {activate ? (
          <Dialog
            open={openD}
            onClose={handleCloseD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Activate User"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                sx={{ marginBottom: 1 }}
              >
                Please enter organization activation details
              </DialogContentText>

              <TextField
                label="Number of Users"
                type="number"
                sx={{ marginBottom: 1 }}
                fullWidth
              ></TextField>
              <DialogContentText
                id="alert-dialog-description"
                sx={{ marginBottom: 1 }}
              >
                Expiry
              </DialogContentText>
              <TextField label="" type="date" fullWidth></TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseD}>Cancel</Button>
              <Button onClick={() => {}} autoFocus>
                Activate
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={openD}
            onClose={handleCloseD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Suspend User"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to suspend the Organisation with id of{" "}
                {suspendedOrg}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseD}>Cancel</Button>
              <Button
                onClick={() => {
                  SuspendOrg(suspendedOrg).then((e) => {
                    if (e.success) {
                      toast.success(e.message);
                      handleCloseD();
                      loadOrganisations();
                    } else {
                      toast.error(e.message);
                      handleCloseD();
                    }
                  });
                }}
                autoFocus
              >
                Suspend
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );

    /*return (
        
      
      );*/
  };

  const renderActionButtons = (params) => {
    console.log(params.row);
    return (
      <Button
        variant="contained"
        sx={{ backgroundColor: params.row.active == "1" ? "#FF0000" : null }}
        onClick={() => {
          if (params.row.active == "1") {
            setActivate(false);
          } else {
            setActivate(true);
          }
          setSuspendedOrg(params.row.id);
          setOpenD(true);
        }}
      >
        {params.row.active == "1" ? "Suspend" : "Activate"}
      </Button>
    );
  };
  const renderShowOrgButton = (params) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          setPopOrgName(params.row.name);
          setPopOrgAddressLine(params.row.address_line);
          setPopOrgCountry(params.row.country);
          setPopOrgIBAN(params.row.iban);
          setPopOrgMakaniZip(params.row.makani_zip);
          setPopOrgMobile(params.row.mobile);
          setPopOrgCity(params.row.city);
          setPopOrgState(params.row.state);
          setPopOrgTel(params.row.tel);
          setPopOrgSubscriptionId(params.row.subscription_id);
          setAnchorEl(true);
        }}
      >
        Show
      </Button>
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "Organisation ID",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      flex: 1,
      field: "image",
      headerName: "Organisation Photo",

      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            style={{
              width: "15%",
              borderRadius: "50%",
              height: undefined,
              aspectRatio: 1,
            }}
            src={params.value}
          />
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Organisation Name",
      flex: 1,
      headerAlign: "left",
    },
    //{ field: "country", headerName: "Country", flex:1,headerAlign: 'left', },
    //{ field: "state", headerName: "State", flex:1,headerAlign: 'left', },
    //{ field: "city", headerName: "City", flex:1,headerAlign: 'left', },
    //{ field: "address_line", headerName: "Address Line", flex:1,headerAlign: 'left', },
    //{ field: "makani_zip", headerName: "Makani Zip", flex:1,headerAlign: 'left', },
    { field: "tel", headerName: "Tel", flex: 1, headerAlign: "left" },
    /*{
      field: "mobile",
      headerName: "Contact",
      type: "number",
      flex:1,
      align:"left",
      headerAlign: 'left',
    },*/
    //{ field: "iban", headerName: "IBAN", flex:1,headerAlign: 'left', },
    {
      field: "subscription_id",
      headerName: "Cases",
      type: "number",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    /*{
      field: "active",
      headerName: "Active Users",
      type: "number",
      flex:1,
      align:"left",
      headerAlign: 'left',
    },*/
    {
      field: "actionShow",
      headerName: "Show",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderShowOrgButton(params),
    },
    {
      field: "actionSuspend",
      headerName: "Suspend",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderActionButtons(params),
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ToastContainer />
      <SuspendDialog></SuspendDialog>
      <DataGrid
        loading={organizations.length === 0}
        rows={organizations}
        columns={columns}
        disableSelectionOnClick
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container spacing={2} px={5} sx={{marginBottom:3,marginTop:1}}>
          <Grid item xs={12} sm={12}>
            <TextField
              contentEditable={false}
              label="Name"
              value={popOrgName}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="Country"
              value={popOrgCountry}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="State"
              value={popOrgState}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="City"
              value={popOrgCity}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="Adress line"
              value={popOrgAddressLine}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="Makani Zip"
              value={popOrgMakaniZip}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="Tel"
              value={popOrgTel}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              contentEditable={false}
              label="Mobile"
              value={popOrgMobile}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              contentEditable={false}
              label="IBAN"
              value={popOrgIBAN}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              contentEditable={false}
              label="Sub ID"
              value={popOrgSubscriptionId}
              fullWidth
            ></TextField>
          </Grid>
        </Grid>
      </Dialog>

      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', }}
        
      >
        <Card elevation={15} sx={{ width: "100%" }}>
          <Box py={4} bgcolor="white">
            <Grid container spacing={2} px={5}>
              <Grid item xs={12} sm={12}>
                <TextField
                  contentEditable={false}
                  label="Name"
                  value={popOrgName}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="Country"
                  value={popOrgCountry}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="State"
                  value={popOrgState}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="City"
                  value={popOrgCity}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="Adress line"
                  value={popOrgAddressLine}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="Makani Zip"
                  value={popOrgMakaniZip}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="Tel"
                  value={popOrgTel}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  contentEditable={false}
                  label="Mobile"
                  value={popOrgMobile}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  contentEditable={false}
                  label="IBAN"
                  value={popOrgIBAN}
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  contentEditable={false}
                  label="Sub ID"
                  value={popOrgSubscriptionId}
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Popover>*/}
    </div>
  );
}
