import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function UpdateOrganization(id,name,country,state,city,address_line,makani_zip,tel,mobile,iban) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    name: name,
    country: country,
    state: state,
    city: city,
    address_line: address_line,
    makani_zip: makani_zip,
    tel: tel,
    mobile: mobile,
    iban: iban,
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  response = await fetch(SERVER_URL + "api/organizations/"+id, requestOptions);
  if (response.status === 200) return response.json();
  return false;
}
