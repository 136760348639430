import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function CheckUNConflict(clientNames) {
  var response;
  var myHeaders = new Headers();


  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(clientNames);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: raw
  };
  response = await fetch(SERVER_URL + "api/clients/un-exists/check", requestOptions);
  if (response.status === 200) return response.json();
  return false;
  /*
  let keywords = clientNames.join(" ");//.trim().replace(" ", "|");
  let includes = clientNames.join(" ");//.trim().replace(" ", "+");

  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
    };
  response = await fetch("https://scsanctions.un.org/en?keywords=+"+keywords+"&per-page=2500&sections=s&sections=s&sort=id&includes="+includes+"&excludes=&committee=&nationality=&reference_from=&reference_to=", requestOptions);
  
  if (response.status === 200)
  {
    let exists = Array();
    await response.text().then(function (text) {
    clientNames.forEach((value)=>{
      console.log("Checking for: " + value);
      if (text.includes(value))
      {
        exists.push(value);
      }
    });
    });
    return {"exists":exists}; 
  }
  return false;
  */
}
