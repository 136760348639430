import logo from "../logo.svg";
import "../App.css";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getRole, ROLE_ORGANIZATIONS, ROLE_USER } from "../user/User";
import { useEffect, useState } from "react";
import GetUserInfo from "../api/GetUserInfo";
import { ToastContainer, toast } from "react-toastify";
import EditUser from "../api/EditUser";
import GetOrganization from "../api/GetOrganization";
import UpdateOrganization from "../api/UpdateOrganization";
import CircularProgress from "@mui/material/CircularProgress";
import NumberTextField from "../components/NumberTextField";

import CountrySelect from "../components/CountrySelect";
import { validateNum } from "../helperFunctions/Validation";
function ProfilePage() {
  const [loading, setLoading] = useState(false);

  const [usName, setUsName] = useState("");
  const [usMobile, setUsMobile] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgCountry, setOrgCountry] = useState("");
  const [orgState, setOrgState] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgAddressLine, setOrgAddressLine] = useState("");
  const [orgTell, setOrgTell] = useState("");
  const [orgMobile, setOrgMobile] = useState("");
  const [orgMakaniZip, setOrgMakaniZip] = useState("");
  const [orgIban, setIban] = useState("");

  const submitUser = async () => {
    setLoading(true);
    if (getRole() == ROLE_USER) {
      if (usName != "" && usMobile != "") {
        if (validateNum(usMobile)) {
          let response = await EditUser(usName, usMobile);
          console.log(response);
          if (response) {
            toast.success("updated successfully");
          } else {
            toast.error("failed to update profile");
          }
        } else {
          toast.error("please enter a valid mobile number");
        }
      } else {
        toast.error("please fill all fields");
      }
    } else {
      if (getRole() == ROLE_ORGANIZATIONS) {
        if(orgName!=""&&
          orgCountry!=""&&
          orgState!=""&&
          orgCity!=""&&
          orgAddressLine!=""&&
          orgMakaniZip!=""&&
          orgTell!=""&&
          orgMobile!=""&&
          orgIban!="")
        {
          if(validateNum(orgTell)&&validateNum(orgMobile))
          {

          
        let userInfo = await GetUserInfo();
        let response = await UpdateOrganization(
          userInfo.id,
          orgName,
          orgCountry,
          orgState,
          orgCity,
          orgAddressLine,
          orgMakaniZip,
          orgTell,
          orgMobile,
          orgIban
        );
        if (response) {
          toast.success("updated successfully");
        } else {
          toast.error("failed to update profile");
        }
      }
      else
      {
        toast.error("please enter a valid mobile & Tel number");
      }
      }
      }
      else
      {
        toast.error("please fill all fields");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (getRole() == ROLE_USER) {
        let userInfo = await GetUserInfo();
        if (userInfo) {
          setUsName(userInfo.name);
          setUsMobile(userInfo.email);
        }
      } else {
        if (getRole() == ROLE_ORGANIZATIONS) {
          let orga = await GetOrganization();
          console.log("....................");
          console.log(orga);
          if (orga) {
            setOrgName(orga.name);
            setOrgCountry(orga.country);
            setOrgCity(orga.city);
            setOrgState(orga.state);
            setOrgAddressLine(orga.address_line);
            setOrgTell(orga.tel);
            setOrgMobile(orga.mobile);
            setIban(orga.iban);
            setOrgMakaniZip(orga.makani_zip);
          }
        }
      }
    }
    fetchData();
  }, []);

  if (getRole() == ROLE_ORGANIZATIONS) {
    return (
      <Card elevation={15} sx={{ width: "40%" }}>
        <ToastContainer />
        <Box py={4} bgcolor="white">
          <Grid container spacing={2} px={5}>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(v) => {
                  setOrgName(v.target.value);
                }}
                value={orgName}
                label="Organisation Name"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <CountrySelect
                value={orgCountry}
                onChange={setOrgCountry}
              ></CountrySelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(v) => {
                  setOrgState(v.target.value);
                }}
                value={orgState}
                label="State"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(v) => {
                  setOrgCity(v.target.value);
                }}
                value={orgCity}
                label="City"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(v) => {
                  setOrgAddressLine(v.target.value);
                }}
                value={orgAddressLine}
                label="Address Line"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(v) => {
                  setOrgMakaniZip(v.target.value);
                }}
                value={orgMakaniZip}
                label="Makani Zip"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(v) => {
                  setIban(v.target.value);
                }}
                value={orgIban}
                label="IBAN"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                onChange={setOrgTell}
                label="Tel"
                value={orgTell}
              ></NumberTextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextField
                onChange={setOrgMobile}
                label="Mobile"
                value={orgMobile}
              ></NumberTextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => submitUser()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  } else {
    return (
      <Card elevation={15} sx={{ width: "40%" }}>
        <ToastContainer />
        <Box py={8} bgcolor="white">
          <Grid container spacing={2} px={10}>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(v) => {
                  setUsName(v.target.value);
                }}
                value={usName}
                label="Name"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={(v) => {
                  setUsMobile(v.target.value);
                }}
                value={usMobile}
                label="Mobile"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                onClick={() => submitUser()}
                variant="contained"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  }
}

export default ProfilePage;
