import logo from "../logo.svg";
import "../App.css";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useState } from "react";
import Register from "../api/Register";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import EmailTextField from "../components/EmailTextField"
import { validateEmail } from "../helperFunctions/Validation";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const onRegisterClick = async () => {
    setLoading(true);
    if (
      email == "" ||
      organisationName == "" ||
      password == "" ||
      confirmPassword == ""
    ) {
      toast.error("please fill all the fields");
    } else {
      if (password == confirmPassword && password != "") {
        if(validateEmail(email))
        {
        let response = await Register(organisationName, email, password);
        if (response && response.success) {
          toast.success("User created successfully");
        }
      }
      else
      {
        toast.error("Please type a valid email address");
      }

      } else {
        if (password != confirmPassword)
          toast.error("please repeat your password correctly");
        else toast.error("please enter a valid password");
      }
    }
    setLoading(false);
  };

  return (
    <div className="App">
      <div className="App-header" style={{backgroundImage: `url("https://lawyeroid.ai/img/fullImages/pic2.jpg")` ,backgroundPosition:'center',backgroundSize:'cover'}}>
        <img
          src={require("../images/logo.png")}
          style={{
            width: "10%",
            position: "absolute",
            top: 0,
          }}
        ></img>
        <ToastContainer />
        <Card elevation={15} sx={{ width: "30%" }}>
          <Box py={8} bgcolor="white">
            <Grid container spacing={2} px={10}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                  Please fill your oganization details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <EmailTextField onChange={setEmail}></EmailTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setOrganisationName(v.target.value)}
                  label="Organization name"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setPassword(v.target.value)}
                  label="Password"
                  type="password"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setConfirmPassword(v.target.value)}
                  label="Confirm Password"
                  type="password"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  onClick={() => onRegisterClick()}
                  fullWidth
                >
                  Register
                </Button>
              </Grid>

              <Grid px={2} xs={12} sm={12} sx={{ marginY: 1 }}>
                <Link
                  href="/signin"
                  sx={{ fontSize: 15, display: "flex", flexDirection: "row" }}
                >
                  Back To Sign In
                </Link>
              </Grid>
              <Grid xs={12} sm={12} sx={{ marginY: 1 }}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default RegisterPage;
//<div style={{backgroundColor:'white',flexDirection:'column',display:'flex',height:'50%',width:'25%',}}>
//color="success">

/**
  <img src={require("../images/logo.png")}
          style={{
            width: "10%",
            height:"10%",
            
          }}
          ></img>  
 */
