import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";
import GetUserInfo from "./GetUserInfo";
export default async function UploadClientFile(file) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + getToken());
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    data: file,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };


  

  let user = await GetUserInfo();
  if (user) {
    response = await fetch(
      SERVER_URL + "api/clients/uploadcsv/upload",
      requestOptions
    );
    if (response.status === 200) return response.json();
  }
  return false;
}
