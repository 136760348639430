import logo from "../logo.svg";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddUser from "../api/AddUser";
import { ToastContainer, toast } from "react-toastify";
import { validateEmail } from "../helperFunctions/Validation";
import EmailTextField from "../components/EmailTextField";


function AddUserPage() {
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  return (
    <div className="App">
      <div className="App-header">
        <Button
          onClick={() => {
            navigate("/");
          }}
          sx={{ position: "absolute", left: 10, top: 10 }}
        >
          Back To Main Page
        </Button>
        <ToastContainer />
        <Card elevation={15} sx={{ width: "30%" }}>
          <Box py={8} bgcolor="white">
            <Grid container spacing={2} px={10}>
              <Grid item xs={12} sm={12}>
                <EmailTextField
                onChange={setEmail}
                >

                </EmailTextField>
                
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setName(v.target.value)}
                  label="Name"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setMobile(v.target.value)}
                  label="Mobile"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={() => {
                    AddUser(name, email, mobile).then((r) => {
                      if (r) {
                        toast.info(r.message)
                      }
                      else
                      {
                        toast.error("could not create the user")
                      }
                    });
                  }}
                  variant="contained"
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default AddUserPage;
