import { SERVER_URL } from "../constants";

export default async function LogIn(username, password) {
  var response;
  var formdata = new FormData();
  formdata.append("grant_type", "password");
  formdata.append("client_id", "2");
  formdata.append("client_secret", "HOqjUMcB6ROdfbNirRNhL653vMRS15IdOzlT4ric");
  formdata.append("username", username);
  formdata.append("password", password);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  response = await fetch(SERVER_URL + "oauth/token", requestOptions);
  if (response.status === 200) return response.json();
  return false;
}
