import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";


import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { CssBaseline } from "@mui/material";
function Layout({ children }) {
  const [open, setOpen] = React.useState(true);
  const [active, setActive] = useState('')
  const toggleOpen = () => setOpen(prev => !prev)
  const theme = useTheme();
  const drawerWidth = 240;


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar open={open} title={active} toggleOpen={toggleOpen} />
      <Sidebar open={open} active={active} setActive={setActive} toggleOpen={toggleOpen} />

      <main className="App-header" style={{
        flexGrow: 1,
        backgroundImage: `url("https://lawyeroid.ai/img/fullImages/pic2.jpg")`, backgroundPosition: 'center', backgroundSize: 'cover',

        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        }),
      }}>
        {children}
      </main>

    </Box>
  )
}

export default Layout