import logo from "../logo.svg";
import "../App.css";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item
} from "@mui/material";
import { useState } from "react";
import PasswordResetRequest from "../api/PasswordResetRequest"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EmailTextField from "../components/EmailTextField"
function ForgetPasswordPage() {
  let navigate = useNavigate();
  const [userID,setUserID] = useState("");

  const onSendPasswordResetClick= async ()=>
  {
    if(userID=="")
    {
      toast.error("please type your user ID")
    }
    else
    {
      let response = await PasswordResetRequest(userID)
      if(response)
      {
        toast.success(response.message)
        navigate("/otp");
      }
      else{
        toast.error("Error occured, please check your user ID")
      }
    }
  }

  return (
    <div className="App">
      <div className="App-header" style={{backgroundImage: `url("https://lawyeroid.ai/img/fullImages/pic2.jpg")` ,backgroundPosition:'center',backgroundSize:'cover'}}>
        <img
          src={require("../images/logo.png")}
          style={{
            width: "10%",
            position: "absolute",
            top: 0,
          }}
        ></img>
        <ToastContainer />
        <Card elevation={15} sx={{ width: "30%" }}>
          <Box py={8} bgcolor="white">
            <Grid container spacing={2} px={10}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                Please Enter your registered email id, and OTP will be sent to your email.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <EmailTextField onChange={setUserID}></EmailTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button onClick={()=>onSendPasswordResetClick()} variant="contained" fullWidth>
                  Submit
                </Button>
              </Grid>
              <Grid px={2} xs={12} sm={12} sx={{ marginY: 1, }}>
                
                  <Link
                    href="/signin"
                    sx={{ fontSize: 15,display:'flex',flexDirection:'row' }}
                  >
                    Back To Sign In
                  </Link>
                
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default ForgetPasswordPage;