import Cookies from "js-cookie";

const USER_COOKIES_KEY = "token";
const USER_ROLE_KEY = "role";
const ROLE_ORGANIZATIONS = "organization";
const ROLE_USER = "user";
const NAME_KEY = "name";
const STATE_KEY = "state";

export { ROLE_ORGANIZATIONS,ROLE_USER};
export function isAuth() {
  let cookie = Cookies.get(USER_COOKIES_KEY);
  if (cookie) return true;
  return false;
}

export function getToken() {
  let cookie = Cookies.get(USER_COOKIES_KEY);
  if (cookie) return cookie;
  return false;
}

export function unAuth() {
  Cookies.remove(USER_COOKIES_KEY);
}

export function auth(token) {
  Cookies.set(USER_COOKIES_KEY, token);
}

export function setRole(role) {
  Cookies.set(USER_ROLE_KEY, role);
}

export function getRole() {
  let cookie = Cookies.get(USER_ROLE_KEY);
  if (cookie) return cookie;
  return false;
}

export function setName(name) {
  Cookies.set(NAME_KEY, name);
}

export function getName() {
  let cookie = Cookies.get(NAME_KEY);
  if (cookie) return cookie;
  return false;
}

export function setWorkflowState(state) {
  Cookies.set(STATE_KEY, JSON.stringify(state));
}

export function getWorkflowState() {
  let cookie = Cookies.get(STATE_KEY);
  if (cookie) 
    return JSON.parse(cookie);
  return false;
}