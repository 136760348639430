import Container from './components/Container'
import SelectButtons from './components/SelectButtons'
const SERVER_URL = "https://app.lawyeroid.ai/lawyeroid_backend/public/";
//const SERVER_URL = "https://app.lawyeroid.ai/lawyeroid_backend/public/";
const MOCK_BACKEND = false;
const ALL_COMPONENTS=[
    <Container id={1}  type="Container"  text="Selection of an International Jurisdiction"  hint=""  details={[{"next_component_id":2}]}  store=""> </Container>,
    <SelectButtons id={3}  type="SelectButtons"  text="Which Party Do you Represent?"  hint=""  details={[{"text":"Claimant","next_component_id":6},{"text":"Defendant","next_component_id":-1}]}  store="Input2"> </SelectButtons>,
    <Container id={2}  type="Container"  text="Selection of an International Jurisdiction"  hint=""  details={[{"next_component_id":3}]}  store=""> </Container>,
    
]

export { SERVER_URL, MOCK_BACKEND,ALL_COMPONENTS };