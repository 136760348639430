import { useEffect, useState } from "react";
import React from "react";
import { useRef } from "react";

import { Button, Card, CardMedia, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { matchPath } from "react-router-dom";
import { TheaterComedy, ThirtyFpsSelect, ThumbUpSharp } from "@mui/icons-material";
import { TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { margin } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import HTMLText from "../components/HTMLText";

import CheckClientExists from "../api/CheckClientExists";
import CheckUNConflict from "../api/CheckUNConflict";

import "../App.css";
import UpdateLawsuitDetails from "../api/UpdateLawsuitDetails";
import GetCase from "../api/GetCase";
import { withRouter } from "../components/withRouter";
// import allComponents from "../assets/staticData/allComponents.json";


let allValues = {};

 class CasePage extends React.Component {
  
  constructor(props) {
    super(props);
    console.log("%c constructor" , "color: green");

    this.state = {
      containers: new Array(),
      statement: new Array(),
      elements: new Array(),
      allComponents:[],
      caseTitle:"",
      caseNumber:"",
      values: {},
    };
    console.log(4);
    console.log(props,"this.props.params");

    this.updatedState = {
      containers: new Array(),
      statement: new Array(),
      elements: new Array(),
      allComponents:[],
      caseTitle:"",
      caseNumber:"",
      values: {},
    };

    this.addElement = this.addElement.bind(this);
  }

  updateState(){
    console.log("UPDATING STATE...");
    this.setState({
      containers: this.updatedState.containers,
      statement: this.updatedState.statement,
      elements: this.updatedState.elements,
      values: this.updatedState.values,
      allComponents: this.updatedState.allComponents,
      caseTitle: this.updatedState.caseTitle,
      caseNumber: this.updatedState.caseNumber
    });
  }

  componentDidMount(){
    console.log("%c componentDidMount" , "color: green");
    console.log("%c calling component did mount" , "color: green");
    fetch('/allComponents.json',{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(res=>{
      res.json().then(data=>{
        GetCase(this.props.params.id).then(v=>{
          if(v.lawsuit_details&&v.lawsuit_details.details)
          {
            console.log("%c Case details" , "color: green");
            console.log(v.lawsuit_details.details);
            this.updatedState = {
              containers: v.lawsuit_details.details.containers,
              statement: v.lawsuit_details.details.statement,
              elements: v.lawsuit_details.details.elements,
              allComponents:data,
              caseTitle:v.title,
              caseNumber:v.lawsuit_number,
              values: v.lawsuit_details.details.values,
            };
            this.updateState();
            return;
          }
          else{
            console.log("%c No details found" , "color: green");
            {
              this.updatedState = {
                containers: new Array(),
                statement: new Array(),
                elements: new Array(),
                allComponents:data,
                caseTitle:v.title,
                caseNumber:v.lawsuit_number,
                values: {},
              };
            this.addElement(1);
            this.updateState();
          }
          }
       })
      })
      .catch(e=>{console.log(e);})
    })
    
  }

  componentWillUnmount(){
    console.log("%c componentWillUnmount: ", "color: green");

  }

  componentDidUpdate() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  storeValue(key, value) {
    console.log("Storing value: " + key + ":" + value);
    let _values = this.updatedState.values;
    _values["%" + key + "%"] = value;
    this.updatedState.values = _values;
    //this.setState({ values: _values });
    allValues = _values;
  }

  evaluateString(str) {
    return Function(`'use strict'; return (${str})`)();
  }

  getValue(key) {
    return this.updatedState.values[key];
  }

  showErrorMessage(message) {
    toast.error(message);
    console.error(message);
  }

  processText(text) {
    let replacements = this.updatedState.values;
    // TODO: check if stored value is array
    let str = text.replace(/%\w+%/g, function (all) {
      return replacements[all] || all;
    });
    return str;
  }

  addElement = (id) => {
    const allComponents=this.updatedState.allComponents;
    console.log("calling add element " + id);
    console.log("Current state");
    console.log(this.updatedState);
    if (id === -1) {
      return;
    }

    let currentContainers = this.updatedState.containers;
    let currentElements = this.updatedState.elements;
    let statement = this.updatedState.statement;
    let values = this.updatedState.values;

    let currentComponent = JSON.parse(JSON.stringify(allComponents[id - 1]));;
    console.log("%celement: " + currentComponent.type, "color: blue");
    currentElements.push(currentComponent);
    console.log(currentComponent);
    const autoAddNextElements = ["Container", "Statement"];
    if (autoAddNextElements.includes(currentComponent.type)) {
      this.addElement(currentComponent.details[0].next_component_id);
    }
    if (currentComponent.type === "Statement") {
      console.log("Setting Statement Text");
      console.log(currentComponent.text);

      statement.push(this.processText(currentComponent.text));
      this.updatedState.statement = statement;
      //this.setState({statement: statement});
    }
    if (currentComponent.type === "IsEqual") {
      console.log(
        "Checking values: " +
        currentComponent.details[0].value1 +
          "    ,    " +
          currentComponent.details[0].value2
      );
      let value1 = this.processText(currentComponent.details[0].value1);
      let value2 = this.processText(currentComponent.details[0].value2);
      console.log("Checking values, " + value1 + ", " + value2, "color:blue");
      if (value1 == value2) {
        console.log(
          "Adding element " +
          currentComponent.details[0].yes_next_component_id,
          "color:blue"
        );
        this.addElement(currentComponent.details[0].yes_next_component_id);
      } else {
        console.log(
          "Adding element " +
          currentComponent.details[0].no_next_component_id,
          "color:blue"
        );
        this.addElement(currentComponent.details[0].no_next_component_id);
      }
    }
    if (currentComponent.type === "IsEnteredValue") {
      console.log(
        "Checking is entered value: " +
        currentComponent.details[0].value1
      );
      let value1 = this.processText(currentComponent.details[0].value1);
      console.log("Checking is entered value, " + value1, "color:blue");
      if (this.updatedState.values[value1]) {
        this.addElement(currentComponent.details[0].yes_next_component_id);
      } else {
        this.addElement(currentComponent.details[0].no_next_component_id);
      }
    }
    if (currentComponent.type === "Expression") {
      let val = this.evaluateString(currentComponent.text);
      this.storeValue(currentComponent.store, val);
      this.addElement(currentComponent.details[0].next_component_id);
    }
    if (currentComponent.type === "SetValue") {
      let val = this.processText(currentComponent.text);
      this.storeValue(currentComponent.store, val);
      this.addElement(currentComponent.details[0].next_component_id);
    }

    let updateResponse = UpdateLawsuitDetails(this.props.params.id,this.updatedState)
    console.log ("%c update response: ", "color:blue");
    console.log (updateResponse);
    this.updatedState.elements = currentElements;
    //this.setState({ elements: currentElements });
  };

  deleteNextComponents = (index) => {
    let currentElements = this.updatedState.elements.slice(0, index);
    //currentElements.length = +1;
    this.updatedState.elements = currentElements;
    //this.setState({elements: currentElements});
  }

  updateCurrentElements = (element, index) => {
    console.log("%celement: " + element, "color: blue");
    let currentElements = this.updatedState.elements;
    currentElements[index] = element;
    this.updatedState.elements = currentElements;
    //this.setState({elements: currentElements});
  }

  // deeltes all elements after the index


  ///// Componenets


  SelectButtons = (props) => {
    const [isDisabled, setDisabled] = useState(false);
    const allComponents=this.updatedState.allComponents;

    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
          marginY: 1,
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <Typography>{this.processText(allComponents[props.id - 1].text)}</Typography>

        {allComponents[props.id - 1].details.map((v, i) => {
          return (
            <Button
              disabled={
                allComponents[props.id - 1].details[i].next_component_id === -1 || isDisabled
              }
              sx={{
                backgroundColor: "#ddfdff",
                color: "004a4e",
                fontWeight: "bold",
                padding: 1,
                margin: 2,
                borderRadius: 5,
                border:props.element.selectedIndex===i?"2px solid #004a4e":"",
                //borderStyle: "solid",
                //borderColor: "15e577"
              }}
              text={v.text}
              value={i}
              key={i}
              onClick={() => {
                if (!(props.element.selectedIndex === undefined) && 
                     (props.element.selectedIndex != i)){
                  // Different answer
                  if (window.confirm("Warning!\nChanging the selection will affect the current progress!"))
                  {
                    // Delete next components
                    this.deleteNextComponents(props.index);
                  }
                }
                if (props.element.selectedIndex == i){
                  return;
                }
                props.element.selectedIndex = i;
                this.updateCurrentElements(props.element, props.index);
                //setDisabled(true);
                this.addElement(
                  allComponents[props.id - 1].details[i].next_component_id
                );
                setTimeout(() => {this.updateState();}, 100);
              }}
            >
              {v.text}
            </Button>
          );
        })}
      </Card>
    );
  };

  SelectRadio = (props) => {
    const allComponents=this.updatedState.allComponents;

    // TODO Change to radio
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
          marginY: 1,
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <FormControl fullWidth>
          <Typography>{allComponents[props.id - 1].text}</Typography>
          <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {allComponents[props.id - 1].details.map((v, i) => {
              return (
                <FormControlLabel
                  value={i}
                  key={i}
                  control={<Radio />}
                  label={<Typography>{v.text}</Typography>}
                  color="#000000"
                  sx={{ color: "#000000" }}
                />
              );
            })}
          </RadioGroup>
          <Button
            onClick={() => {
              // TODO: add element based on selected one
            }}
          >
            {"OK"}
          </Button>
        </FormControl>
      </Card>
    );
  };

  SelectElement = (props) => {
    const [isDisabled, setDisabled] = useState(false);
    const [value, setValue] = useState(props.element.selectedIndex);
    const allComponents=this.updatedState.allComponents;

    const updateUI = () => {
      
    }

    console.log(value,"valuevaluevaluevaluevalue");
    const onChange = (event,  child) => {
      console.log("%c OnChange:" , 'color:blue');
      let currentIndex = child.props.index;
      console.log('Index: ' + child.props.index + ", Saved Index: " + props.element.selectedIndex);
      if (!(props.element.selectedIndex === undefined) && 
           (props.element.selectedIndex != currentIndex)){
            // Different answer1
            if (window.confirm("Warning!\nChanging the selection will affect the current progress!"))
            {
              // Delete next components
              this.deleteNextComponents(props.index);
              
                props.element.selectedIndex = currentIndex;
                props.element.selectedValue = child.props.text;
                this.updateCurrentElements(props.element, props.index);
                //setDisabled(true);
                let nextElementID =
                  allComponents[props.id - 1].details[event.target.value]
                    .next_component_id;
                if (nextElementID != -1) {
                  this.storeValue(props.store, child.props.text);
                  this.addElement(nextElementID);
                }
              setTimeout(() => {this.updateState();}, 100);
              return;
            }
      }
      if (props.element.selectedIndex == currentIndex){
        return;
      }
      props.element.selectedIndex = currentIndex;
      props.element.selectedValue = child.props.text;
      this.updateCurrentElements(props.element, props.index);
      //setDisabled(true);
      let nextElementID =
        allComponents[props.id - 1].details[event.target.value]
          .next_component_id;
      if (nextElementID != -1) {
        this.storeValue(props.store, child.props.text);
        this.addElement(nextElementID);
      }
      setTimeout(() => {this.updateState();}, 100);
      //console.log(child.props.text);
    };
    
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
          marginY: 1,
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <FormControl fullWidth>
          <div><Typography>{
          this.processText(allComponents[props.id - 1].text)
          }</Typography></div>
          
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Select
            disabled={isDisabled}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            onChange={onChange}
            
            defaultValue= {value}

          >
            {allComponents[props.id - 1].details.map((v, i) => {
              return (
                <MenuItem
                  disabled={
                    allComponents[props.id - 1].details[i].next_component_id ===
                    -1
                  }
                  text={v.text}
                  value={i}
                  key={i}
                  index={i}
                >
                  {v.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Card>
    );
  };

  htmlDecode(content) {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  Statement = (props) => {
    let htmltext = this.processText(props.text);
    return <HTMLText text={htmltext}> </HTMLText>;
  };

  NumberInput = (props) => {
    const allComponents=this.updatedState.allComponents;

    const textField = useRef(null);
    const [value, setValue] = useState(props.element.value);
    const [currentValue, setCurrentValue] = useState(props.element.value);
    const [okButtonState, setOkButtonState] = useState(false);
    let maxInput = 100;
    
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <Typography>
          {this.processText(allComponents[props.id - 1].text)}
        </Typography>
        <TextField
          ref={textField}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="number"
          inputProps={{ min: 1, max: maxInput }}
          label=""
        ></TextField>
        <br />
        <Button
          disabled={okButtonState}
          sx={{
            backgroundColor: "#ddfdff",
            color: "004a4e",
            fontWeight: "bold",
            padding: 1,
            margin: 2,
            borderRadius: 5,
          }}
          onClick={() => {
            console.log("currentValue " + currentValue);
            console.log("Value " + value);


            if (parseInt(value) < 1) {
              this.showErrorMessage(
                "Please enter valid number at least 1"
              );
              return;
            }

            if (currentValue > 0 && value != currentValue) {
              if (window.confirm("Warning!\nChanging the value will affect the current progress!")){
                    // Delete next components
                    this.deleteNextComponents(props.index);
              }
              else{
                return;
              }
              //this.showErrorMessage(
              //</Card>  "Changing Value will refresh the workflow."
              //);
              //return;
            }
            
            if (currentValue == value){
              return;
            }
            //setOkButtonState(true);
            setCurrentValue(value);
              
            props.element.value = value;
            this.storeValue(allComponents[props.id - 1].store, value);
            this.updateCurrentElements(props.element, props.index);
            this.addElement(allComponents[props.id - 1].details[0].next_component_id);  
            setTimeout(() => {this.updateState();}, 500);
          }}
        >
          Validate
        </Button>
      </Card>
    );
  };

  TextInput = (props) => {
    const textField = useRef(null);
    const allComponents=this.updatedState.allComponents;

    const [value, setValue] = useState(props.element.value);
    const [currentValue, setCurrentValue] = useState(props.element.value);
    const [okButtonState, setOkButtonState] = useState(false);
    let maxInput = 8;

    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
          marginY: 2,
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <Typography>
          {this.processText(allComponents[props.id - 1].text)}
        </Typography>
        <TextField
        sx={{width:"50%"}}
          ref={textField}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label=""
        ></TextField>
        <br />
        <Button
          disabled={okButtonState}
          sx={{
            backgroundColor: "#ddfdff",
            color: "004a4e",
            fontWeight: "bold",
            padding: 1,
            margin: 2,
            borderRadius: 5,
          }}
          onClick={() => {
            console.log("Value " + value);
            console.log("CurrentValue " + currentValue);
            if (!value) {
              this.showErrorMessage("Please enter text value!");
              return;
            }
            if (allComponents[props.id - 1].details[0].type === "IMO")
            {
              if(!value.match(/^\d{7}(?:\d{2})?$/)){
                this.showErrorMessage("Please enter a valid IMO number (7 Digit)!");
                return;
              }
            }
            if (currentValue == value){
              return;
            }
            else if (currentValue){
              if (window.confirm("Warning!\nChanging the value will affect the current progress!")){
                // Delete next components
                this.deleteNextComponents(props.index + 1);
                this.updateState();
                
              setTimeout(() => {
                setCurrentValue(value);
              props.element.value = value;
              this.updateCurrentElements(props.element, props.index);
              this.storeValue(allComponents[props.id - 1].store, value);
              this.addElement(allComponents[props.id - 1].details[0].next_component_id);
              this.updateState();
              }, 500);
                return;
              }
              else{
                return;
              }
            }
            {
              //setOkButtonState(true);
              setCurrentValue(value);
              props.element.value = value;
              this.updateCurrentElements(props.element, props.index);
              this.storeValue(allComponents[props.id - 1].store, value);
              this.addElement(allComponents[props.id - 1].details[0].next_component_id);
              setTimeout(() => {this.updateState();}, 500);
            } 
            
          }}
        >
          Validate
        </Button>
      </Card>
    );
  };

  ArrayTextInput = (props) => {
    const allComponents=this.updatedState.allComponents;

    const [isDisabled, setDisabled] = useState(props.element.clicked);

    console.log("Creating Array Text Input... ArrayCount: " + this.processText(allComponents[props.id - 1].details[0].arrayCount));
    console.log(this.updatedState.values);
    let arrayCount = parseInt(
      this.getValue(allComponents[props.id - 1].details[0].arrayCount)||0
    );
    let  newArray = Array(); 
    if (props.element.arrayInput){
      newArray = props.element.arrayInput.slice();
    }
    else
    {
      newArray =  Array(arrayCount).fill("");
    }
    //let newArray = Array(arrayCount).fill(""); //.from(Array(arrayCount).keys());
    let store = allComponents[props.id - 1].store;
    return (
      <Card
        sx={{ width: "100%", backgroundColor: "white", padding: 1, marginY: 2 }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <Typography>
          {this.processText(allComponents[props.id - 1].text)}
        </Typography>

        {newArray.map((v, i) => {
          return (
            <div style={{ margin: 10 }}>
              <TextField
              defaultValue={newArray[i]}
              sx={{width:"50%"}}
                onChange={(e) => {
                  
                  newArray[i] = e.target.value;
                  console.log("data change "+ newArray);
                  console.log("props "+ props.element.arrayInput);

                }}
                label={i + 1 + ""}
              ></TextField>
            </div>
          );
        })}
        <Button
        disabled={isDisabled}
          sx={{
            backgroundColor: "#ddfdff",
            color: "004a4e",
            fontWeight: "bold",
            padding: 1,
            margin: 2,
            borderRadius: 5,
            
          }}
          onClick={() => {
            setDisabled(true);
            let shouldbreak = false;
            for (var i = 0; i < newArray.length; i++) {
              if (!newArray[i]) {
                shouldbreak = true;
              }
            }
            if (shouldbreak) {
              this.showErrorMessage("Please fill all the input fields!");
              return;
            }
            console.log("props.arrayinput: " + props.element.arrayInput);
            console.log("newArray: " + newArray);

            //check if current data is imilar to entered data
            //if (props.element.arrayInput && (props.element.arrayInput.toString() === newArray.toString())){
            //  return;
            //}
            // Check if the current value is different than new entered value
            
            this.storeValue(store, newArray);
            props.element.arrayInput = newArray;
            // Clicked to register first time click
            props.element.clicked = true;
            this.updateCurrentElements(props.element, props.index);
            //this.addElement(allComponents[props.id - 1].details[0].next_component_id);
            setTimeout(() => {this.updateState();}, 200);
            setTimeout(() => {this.addElement(allComponents[props.id - 1].details[0].next_component_id);this.updateState();}, 500);
          }}
        >
          Validate
        </Button>
        <Button
          sx={{
            backgroundColor: "#ddfdff",
            color: "004a4e",
            fontWeight: "bold",
            padding: 1,
            margin: 2,
            borderRadius: 5,
            
          }}
          onClick={() => {
                if (window.confirm("Warning!\nChanging the value will affect the current progress!")){
                  // Delete next components
                  this.deleteNextComponents(props.index);
                  setDisabled(false);
                  props.element.clicked = false;
                  this.updateCurrentElements(props.element, props.index);
                  setTimeout(() => {this.updateState();}, 200);
                }
                else{
                  return;
                }
          }}
        >
          Edit
        </Button>
      </Card>
    );
  };

  LocalDBCheck = (props) => {
    const allComponents=this.updatedState.allComponents;

    const [buttonDisabled, setDisabled] = useState(true);
    const [nextComponentId, setNextComponentId] = useState(-1);
    const [callStatus, setCallStatus] = useState("Loading...");
    useEffect(() => {
      async function fetchData() {
        console.log("%cLocalDBCheck", "color:green");
        console.log(allValues[allComponents[props.id - 1].details[0].value]);
        
        let clients_r = await CheckClientExists(allValues[allComponents[props.id - 1].details[0].value]);
        console.log(clients_r);
        if (clients_r.exististing == 0)
        {
          // No conflict, enabling next button
          setCallStatus("No conflict");
          setDisabled(false);
          setNextComponentId(allComponents[props.id - 1].details[0].conflict_free_next_component_id);
        }
        else
        {
          // Conflict, moving to next
          setCallStatus("Conflict found with local client database!");
          setDisabled(false);
          setNextComponentId(allComponents[props.id - 1].details[0].conflict_next_component_id);
        }
        
        
      }
      fetchData();
    }, []);

    // TODO go to next based on API check
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
        }}
      >
        <Button
        sx={{backgroundColor: "#ddfdff",
        color: "004a4e",
        fontWeight: "bold",
        padding: 1,
        margin: 2,
        borderRadius: 5
      }}
          onClick={() => {
            //console.log("nextComponentId: "+nextComponentId);
            // CHECK for Disabled
            setDisabled(true);
            this.addElement(nextComponentId);
            setTimeout(() => {this.updateState();}, 500);
          }}
          disabled={buttonDisabled} 
        >
          Next
        </Button>
      </Card>
    );
  };

  UNAPICheck = (props) => {
    const allComponents=this.updatedState.allComponents;

    const [buttonDisabled, setDisabled] = useState(true);
    const [nextComponentId, setNextComponentId] = useState(-1);
    const [callStatus, setCallStatus] = useState("Loading...");

    useEffect(() => {
      async function fetchData() {
        console.log("%cUNAPICheck", "color:green");
        console.log(allValues[allComponents[props.id - 1].details[0].value]);
        let valueToCheck = allValues[allComponents[props.id - 1].details[0].value];
        let clients_r = await CheckUNConflict(valueToCheck);
        console.log(clients_r);
        if (clients_r.exists.length == 0)
        {
          // No conflict, enabling next button
          props.element.callStatus = "Not found in UN Security Council Database";
          //this.updateCurrentElements(props.element, props.index);
          setCallStatus(props.element.callStatus);
          setDisabled(false);
          setNextComponentId(allComponents[props.id - 1].details[0].conflict_free_next_component_id); 
        }
        else
        {
          // Conflict, moving to next
          props.element.callStatus = "Found in UN Security Council Database! " + clients_r.exists.join(", ");
          //this.updateCurrentElements(props.element, props.index);
          setCallStatus(props.element.callStatus);
          setDisabled(false);
          setNextComponentId(allComponents[props.id - 1].details[0].conflict_next_component_id);
        }
      }
      //if (!props.element.checkedbefore)
      {
        props.element.checkedbefore = true;
        this.updateCurrentElements(props.element, props.index);
        fetchData();
      }
      // else
      // {
      //   setCallStatus(props.element.callStatus);
      // }
    }, []);

    // TODO go to next based on API check
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: 1,
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
        <Tooltip
          className="tooltip"
          title={allComponents[props.id - 1].hint}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        }
        <Typography>Checking for UN API</Typography>
        <Typography>{callStatus}</Typography>
        <Typography>For more details, follow the {'\u00A0'}
          <a 
                href={"https://scsanctions.un.org/en?keywords=+"+allValues[allComponents[props.id - 1].details[0].value]+
                        "&per-page=2500&sections=s&sections=s&sort=id&includes="+allValues[allComponents[props.id - 1].details[0].value]+
                        "&excludes=&committee=&nationality=&reference_from=&reference_to="} target="_blank">
            UN Security Council Search Website
          </a>
        </Typography>
        
        <Button
        sx={{backgroundColor: "#ddfdff",
        color: "004a4e",
        fontWeight: "bold",
        padding: 1,
        margin: 2,
        borderRadius: 5
      }}
          onClick={() => {
            //console.log("nextComponentId: "+nextComponentId);
            setDisabled(true);
            this.addElement(nextComponentId);
            setTimeout(() => {this.updateState();}, 500);
          }}
          disabled={buttonDisabled} 
        >
          Next
        </Button>
      </Card>
    );
  };

  Expression = (props) => {
    return <div></div>;
  };

  IsEnteredValue = (props) => {
    return;
  }

  SetWorkFlowValue = (props) => {
    return;
  }

  IsEqual = (props) => {
    const allComponents=this.updatedState.allComponents;

    return;
    <Card
      sx={{
        width: "100%",
        backgroundColor: "white",
        padding: 1,
      }}
    >
      <Typography>
        {this.processText(allComponents[props.id - 1].text)}
      </Typography>
    </Card>;
  };

  Container = (props) => {
    const allComponents=this.updatedState.allComponents;

    return (
      <Card
        sx={{
          background:
            "linear-gradient(90deg, rgba(0,74,78,1) 0%, rgba(0,127,134,1) 35%, rgba(2,158,167,1) 100%)",
          padding: 2,
          marginY: 2,
          color: "white",
        }}
      >
        {
          allComponents[props.id - 1].hint !== "" &&
            <Tooltip
            className="tooltip"
            title={allComponents[props.id - 1].hint}
            >
            <IconButton>
              <InfoIcon sx={{color:"white"}}/>
            </IconButton>
          </Tooltip>
        }
        

        <div>{this.processText(props.text)}</div>
      </Card>
    );
  };

  AllContainer = (props) => {
    return (
      <div style={{ padding: 2, height: "100%", flex: 2 }}>
        {props.containers.map((v) => (
          <this.Container id={v} key={v}></this.Container>
        ))}
      </div>
    );
  };

  FinalStatement = (props) => {
    return (
      <div
        style={{
          padding: 2,
          position: "fixed",
          top: 55,
          flex: 1,
          marginRight: 5,
          marginLeft: 5,
          position: "-webkit-sticky" /* Safari */,
          position: "sticky",
          maxHeight: "300px",
          overflowY: "scroll",
        }}
      >
        <Card
          sx={{
            backgroundColor: "#f0f0f0",
            padding: 2,
            marginY: 2,
          }}
        >
          <div>Final Statement</div>
          <div>
            {this.updatedState.statement.map((v) => {

              return <Typography>{v}</Typography>;
              //return <div dangerouslySetInnerHTML={{__html: v}} />
            })}
          </div>
        </Card>
      </div>
    );
  };

  AllComponents = (props) => {
    let _this = this;
    //console.log(props.elements);
    return (
      <div style={{ padding: 2, height: "100%", flex: 2 }}>
    {  this.updatedState.caseNumber &&<Typography sx={{color:"black",margin:"auto", textAlign:"center", fontWeight:"bold"}}> {"Case: " + this.updatedState.caseNumber + ", " + this.updatedState.caseTitle} </Typography>}

        {props.elements.map(function (element, index) {
          switch (element.type) {
            case "Container":
              {
                return (
                  <_this.Container
                    id={element.id}
                    key={element.id}
                    text={element.text}
                    element = {element}
                    index = {index}
                  ></_this.Container>
                );
              }
              break;
            case "Statement":
              {
                return (
                  <_this.Statement
                    id={element.id}
                    key={element.id}
                    text={element.text}
                    element = {element}
                    index = {index}
                  ></_this.Statement>
                );
              }
              break;
            case "Select":
              {
                return (
                  <_this.SelectElement
                    id={element.id}
                    key={Math.floor(Math.random() * 1000)}
                    text={element.text}
                    store={element.store}
                    element = {element}
                    index = {index}
                  ></_this.SelectElement>
                );
              }
              break;
            case "SelectButtons":
              return (
                <_this.SelectButtons
                  id={element.id}
                  key={element.id}
                  element = {element}
                  index = {index}
                ></_this.SelectButtons>
              );
              break;
            case "SelectRadio":
              return (
                <_this.SelectRadio
                  id={element.id}
                  key={element.id}
                  element = {element}
                  index = {index}
                ></_this.SelectRadio>
              );
              break;
            case "NumberInput":
              return (
                <_this.NumberInput
                  id={element.id}
                  key={element.id}
                  element = {element}
                  index = {index}
                ></_this.NumberInput>
              );
              break;
            case "TextInput":
              return (
                <_this.TextInput
                  id={element.id}
                  key={Math.floor(Math.random() * 100)}
                  element = {element}
                  index = {index}
                ></_this.TextInput>
              );
              break;
            case "Expression":
              return (
                <_this.Expression
                  id={element.id}
                  key={element.id}
                  element = {element}
                  index = {index}
                ></_this.Expression>
              );
              break;
            case "IsEqual":
              return (
                <_this.IsEqual 
                id={element.id} 
                key={element.id}
                element = {element}
                index = {index}
                >
                </_this.IsEqual>
              );
              break;
              case "SetValue":
              return (
                <_this.SetWorkFlowValue 
                id={element.id} 
                key={element.id}
                element = {element}
                index = {index}
                >
                </_this.SetWorkFlowValue>
              );
              break;
              case "IsEnteredValue":
              return (
                <_this.IsEnteredValue 
                id={element.id} 
                key={element.id}
                element = {element}
                index = {index}
                >
                </_this.IsEnteredValue>
              );
              break;
            case "ArrayTextInput":
              return (
                <_this.ArrayTextInput
                  id={element.id}
                  key={(Math.floor(Math.random()*100))}
                  element = {element}
                  index = {index}
                ></_this.ArrayTextInput>
              );
              break;
            case "UNAPICheck":
              return (
                <_this.UNAPICheck
                  id={element.id}
                  key={element.id}
                  element = {element}
                  index = {index}
                ></_this.UNAPICheck>
              );
              break;
            case "LocalDBCheck":
              return (
                <_this.LocalDBCheck
                  id={element.id}
                  key={element.id}
                  element = {element}
                  index = {index}
                ></_this.LocalDBCheck>
              );
              break;
            default:
              break;
          }
          return null;
        })}
      </div>
    );
  };

  render() {
    return (
      <div
        style={{
          padding: 2,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginTop:"50px"
        }}
      >
        <ToastContainer />
        <this.AllComponents elements={this.state.elements}></this.AllComponents>

      </div>
    );
  }
}
export default withRouter(CasePage);
