import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";
import GetUserInfo from "./GetUserInfo";

export default async function Subscribe() {
  var response;
  var myHeaders = new Headers();

  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + getToken());

  var raw = JSON.stringify({});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  let user = await GetUserInfo();
  if (user) {
    /////////////////////////////////////////////hier
    response = await fetch(
      SERVER_URL + "api/organizations/"+user.id+"/subscribe",
      requestOptions
    );
    if (response.status === 200) return response.json();
    return false;
  }
  return false;
}
