import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MainPage from "./pages/MainPage";
//import { BrowserRouter as Router, Route } from "react-router-dom";
import SignInPage from "./pages/SignInPage";
import RegisterPage from "./pages/RegisterPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import OtpPage from "./pages/OtpPage";
import AddClientPage from "./pages/AddClientPage";
import CasePage from "./pages/CasePage";
import AddUserPage from "./pages/AddUserPage";
import { Routes, Route, Link } from "react-router-dom";
import ProfilePage from "./pages/ProfilePage";
import OrganizationsList from "./pages/OrganizationsList";
import SubscriptionPage from "./pages/SubscriptionPage";
import OrganisationUsersPage from "./pages/OrganisationUsersPage";
import CasesPage from "./pages/CasesPage";
import DashboardPage from "./pages/DashboardPage"
import MainPage2 from "./pages/MainPage2";
import Sittings from "./pages/Sittings";
import ClientsList from "./pages/ClientsList";
const theme = createTheme({
  palette: {
    primary: {
      main: "#14595d",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/newcase" element={<CasePage />} />
        <Route path="/" element={<MainPage2 />} >
          <Route path="/dashboard" element={<DashboardPage sx={{ width: "100%" }} />} />
          <Route path="/organizations" element={<OrganizationsList />} />
          <Route path="/system-variables" element={<Sittings />} />
          <Route path="/users" element={<OrganisationUsersPage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/clients" element={<ClientsList />} />
          <Route path="/cases" element={<CasesPage />} />
          <Route path="/cases/:id" element={<CasePage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
