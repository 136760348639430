import React from "react";
import { Outlet } from "react-router-dom";

import Layout from "../components/Layout";
function MainPage2() {


  return (
    <Layout >
      <Outlet />
    </Layout>

  )
}

export default MainPage2