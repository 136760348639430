import logo from "../logo.svg";
import "../App.css";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import GetOrganization from "../api/GetOrganization";
import React, { useEffect, useState } from "react";
import Subscribe from "../api/Subscribe";
import CircularProgress from "@mui/material/CircularProgress";

function SubscriptionPage() {
  const [subId, setSubId] = useState(null);

  const subscribe = () => {

    Subscribe().then((e)=>{
      console.log(e)
      window.location.reload(false);
    })
  };

  const SubscriptionDetails = () => {
    console.log("qqqqqqqqqqqqq")
    console.log(subId)
    if (subId == 0 || subId == "0") {
      return (
        <Stack sx={{ textAlign: "center" }} spacing={1}>
          <Typography variant="h4" component="h2">
            10 Users
          </Typography>
          <Typography variant="h2" component="h2">
            4000$
          </Typography>

          <Typography variant="h6" component="h2">
            Far concluded not his something extremity. Want four we face an he
            gate. On he of played he ladies answer little though nature.
            Blessing oh do pleasure as so formerly. Took four spot soon led size
            you. Outlived it received he material. Him yourself joy moderate off
            repeated laughter outweigh screened.
          </Typography>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" fullWidth onClick={() => subscribe()}>
              Buy Now
            </Button>
          </Grid>
        </Stack>
      );
    } else {
      if(subId==null)
      {
       return (
        <Stack sx={{ textAlign: "center" }} spacing={1}>
       <CircularProgress />
       </Stack>
       )
      }
      return (
        <Stack sx={{ textAlign: "center" ,alignContent:'center',alignItems:'center'}} spacing={1}>
          <Typography variant="h4" component="h1">
            Currently Subscribed for 10 Users
          </Typography>
        </Stack>
      );
    }
  };
  useEffect(() => {
    GetOrganization().then((response) => {
      console.log("orgaaaaa")
      console.log(response)
      if (response && response.subscription_id) {
        setSubId(response.subscription_id);
      }
    });
  }, []);

  return (
    <Card elevation={15} sx={{ width: "50%", paddingX: 2 }}>
      <Box py={8} bgcolor="white">
        <SubscriptionDetails></SubscriptionDetails>
      </Box>
    </Card>
  );
}

export default SubscriptionPage;
