import { TextField } from "@mui/material";
import { useState } from "react";
import { validateNum } from "../helperFunctions/Validation";
export default function NumberTextField(props)
{

    const [numVal, setNumVal] = useState(true);
    
    return (
        <TextField
                  onChange={(v) => {
                    setNumVal(validateNum(v.target.value))
                    props.onChange(v.target.value)  
                    }}
                    label={props.label}
                    value={props.value}
                  fullWidth
                  error={!numVal}
                ></TextField>
    )
}