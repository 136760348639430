import logo from "../logo.svg";
import "../App.css";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
} from "@mui/material";
import PasswordOTP from "../api/PasswordOTP";
import { useState } from "react";
import { auth } from "../user/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import EmailTextField from "../components/EmailTextField"
function OtpPage() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");

  const success = async () => {
    if(email==""||otp==""||password=="")
    {
        toast.error("please fill all the fields")
    }
    else
    {
        let response = await PasswordOTP(email,otp,password)
        if(response&&response.success)
        {
            toast.success(response.message)
            navigate("/signin")
        }
        else{
            toast.error("error occured, please check your otp")
        }
    }
  };
  return (
    <div className="App">
      <div className="App-header">
      <img
          src={require("../images/logo.png")}
          style={{
            width: "10%",
            position: "absolute",
            top: 0,
          }}
        ></img>
        <ToastContainer />
        <Button
          onClick={() => {
            navigate("/signin");
          }}
          sx={{ position: "absolute", left: 10, top: 10 }}
        >
          Back To Login
        </Button>
        <Card elevation={15} sx={{ width: "30%" }}>
          <Box py={8} bgcolor="white">
            <Grid container spacing={2} px={10}>
            <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                Please enter the OTP sent to your email, and the new password
                </Typography>
              </Grid>
            
              <Grid item xs={12} sm={12}>
                <EmailTextField onChange={setEmail}></EmailTextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setOtp(v.target.value)}
                  label="OTP"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setPassword(v.target.value)}
                  label="New Password"
                  type="password"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button onClick={() => success()} variant="contained" fullWidth>
                  Submit
                </Button>
              </Grid>
              <Grid px={2} xs={12} sm={6} sx={{ marginY: 1, }}>
                
                  <Link
                    href="/signin"
                    sx={{ fontSize: 15,display:'flex',flexDirection:'row' }}
                  >
                    Back To Sign In
                  </Link>
                
              </Grid>
              <Grid  xs={12} sm={6} sx={{ marginY: 1, }}>
              {/*<Link
                    onClick={() => navigate("/forgetpassword")}
                    sx={{ fontSize: 15,display:'flex',flexDirection:'row-reverse' }}
                  >
                    Resend OTP
        </Link>*/}
                
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default OtpPage;
