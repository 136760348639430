import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function GetClients() {
  var response;
  var myHeaders = new Headers();
  
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  response = await fetch(SERVER_URL + "api/clients", requestOptions);
  if (response.status === 200) return response.json();
  return false;
}
