export function validateEmail(email) 
 {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function validateNum(num) 
 {
  var re = /^\d+$/;
  return re.test(num);
}
