import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function UpdateLawsuitDetails(id,details) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");
  console.log("%c Updating lawsuit details., id: " + id + "..", 'background: #222; color: #bada55');
  console.log(details);
  var raw = JSON.stringify({
    details: details,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  response = await fetch(SERVER_URL + "api/lawsuits/"+id+"/details", requestOptions);
  if (response.status === 200){
    console.log("response.status 200");
    return await response.json();
  }
  console.log("response.status " + response.status);
  return false;
}
