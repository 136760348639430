import { Card } from "@mui/material";
import { React } from "react";

export default function HTMLText(props) {
  return (<Card
    sx={{
      width: "100%",
      backgroundColor: "white",
      padding: 1,
    }}
  >
  <div style={{color:"black"}} dangerouslySetInnerHTML={{__html:props.text}}></div>
  </Card>);
}