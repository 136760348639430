import { SERVER_URL } from "../constants";

export default async function PasswordResetRequest(userID) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "email": userID
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  response = await fetch(SERVER_URL + "api/users/passwordresetrequest", requestOptions);
  if (response.status === 200) return response.json();
  return false;
}
