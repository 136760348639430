import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import WorkIcon from "@mui/icons-material/Work";

import BusinessIcon from '@mui/icons-material/Business';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
export const sidebarList = [{
    name: "Dashboard",
    icon: <DashboardIcon />, 
    
    link: "/",
    role: ["admin"]
}, {
    name: "Organizations",
    icon: <BusinessIcon />,
    link: "/organizations",
    role: ["admin"]

}, {
    name: "System Variables",
    icon: <SettingsSuggestIcon />,
    link: "/system-variables",
    role: ["admin"]

},
{
    name: "Users",
    icon: <GroupIcon />,
    link: "/users",
    role: ["organization"]

},
{
    name: "Subscription",
    icon: <AppRegistrationIcon />,
    link: "/subscription",
    role: ["organization"]

},
{
    name: "Clients",
    icon: <SupervisedUserCircleIcon />,
    link: "/clients",
    role: ["organization", "user"]

},
{
    name: "Cases",
    icon: <WorkIcon />,
    link: "/cases",
    role: ["organization", "user"]
},

]
export const listHasRole = (role) => {
    const listWithSameRole = sidebarList.filter(item => item.role.indexOf(role)>=0)
    return listWithSameRole
}
