import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function AddUser(name, email,mobile) {
  var response;
  
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "name": name,
    "email": email,
    "mob": mobile
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  response = await fetch(SERVER_URL + "api/users", requestOptions);
  if (response.status === 200||response.status === 201) return response.json();
  return false;
}
