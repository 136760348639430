import logo from "../logo.svg";
import "../App.css";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  Link,
  Item,
} from "@mui/material";
import LogIn from "../api/LogIn";
import { useState } from "react";
import { auth, setName, setRole } from "../user/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import GetUserInfo from "../api/GetUserInfo";
function SignInPage() {
  let navigate = useNavigate();
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const signIn = async () => {
    setLoading(true);
    let response = await LogIn(userID, password);
    if (response) {
      auth(response.access_token);
      let userInfo = await GetUserInfo();
      setRole(userInfo.type);
      setName(userInfo.name);
      setLoading(false);
      navigate("/");
    } else {
      toast.error("unable to login, please check your credentials");
      setLoading(false);
    }
  };
  return (
    <div className="App" >
      <div className="App-header" style={{backgroundImage: `url("https://lawyeroid.ai/img/fullImages/pic2.jpg")` ,backgroundPosition:'center',backgroundSize:'cover'}}>
        <img
          src={require("../images/logo.png")}
          style={{
            width: "10%",
            position: "absolute",
            top: 0,
          }}
        ></img>
        <ToastContainer />
        <Card elevation={15} sx={{ width: "30%" }}>
          <Box py={6} bgcolor="white">
            <Grid container spacing={2} px={5}>
              <Grid item xs={12} sm={12}>
                <Typography color="primary" align="center" variant="h6">
                  Sign in to access Lawyeroid
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setUserID(v.target.value)}
                  label="User ID"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onChange={(v) => setPassword(v.target.value)}
                  label="Password"
                  type="password"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button onClick={() => signIn()} variant="contained" fullWidth>
                  Login
                </Button>
              </Grid>

              <Grid px={2} xs={12} sm={6} sx={{ marginY: 1, }}>
                
                <Link
                  href="/forgetpassword"
                  sx={{ fontSize: 15,display:'flex',flexDirection:'row' }}
                >
                  Forgot Password
                </Link>
              
            </Grid>
            <Grid  xs={12} sm={6} sx={{ marginY: 1, }}>
                
                <Link
                  href="/register"
                  sx={{ fontSize: 15,display:'flex',flexDirection:'row-reverse' }}
                >
                  Register
                </Link>
              
            </Grid>

              
              <Grid xs={12} sm={12} sx={{ marginY: 1 }}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default SignInPage;
//<div style={{backgroundColor:'white',flexDirection:'column',display:'flex',height:'50%',width:'25%',}}>
//color="success">

/**
  <img src={require("../images/logo.png")}
          style={{
            width: "10%",
            height:"10%",
            
          }}
          ></img>  
 */
