import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";
export default async function EditCase(id,title, lawsuitNumber) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "title": title,
    "lawsuit_number": lawsuitNumber
  });
  
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  

  response = await fetch(SERVER_URL + "api/lawsuits/"+id, requestOptions);
  if (response.status === 200) return true;
  return false;
}
