import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function DeleteClient(clientsID) {
  var response;
  
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  
  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  response = await fetch(SERVER_URL + "api/clients/"+clientsID, requestOptions);
  if (response.status === 200||response.status === 201) return true;
  return false;
}
