import { SERVER_URL } from "../constants";

export default async function Register(name, email,password) {
  var response;
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    name: name,
    email: email,
    password: password,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  response = await fetch(SERVER_URL + "api/users/register", requestOptions);
  if (response.status === 200) return response.json();
  return false;
}
