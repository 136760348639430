import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";
import GetUserInfo from "./GetUserInfo";

export default async function GetOrganization() {
  var response;
  var myHeaders = new Headers();

  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + getToken());

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let user = await GetUserInfo();
  if (user) {
    console.log(user)
    response = await fetch(
      SERVER_URL + "api/organizations/" +user.organization_id,
      requestOptions
    );
    if (response.status === 200) return response.json();
    return false;
  }
  return false;
}
