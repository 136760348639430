import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Avatar, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CasePage from "./CasePage";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { Grid, TextField, Typography } from "@mui/material";
import AddCase from "../api/AddCase";
import { ToastContainer, toast } from "react-toastify";
import GetCases from "../api/GetCases";
import EditCase from "../api/EditCase";
import EditCaseDetails from "../api/EditCaseDetails";

const rows_c = [
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
  {
    id: 1234,
    title: "Ship Motgage",
    clientName: "Ahmad Awad",
    caseStatus: "Closed",
    dateCreated: "12-12-12",
    Lastupdated: "12-12-12",
  },
];

export default function CasesPage() {
  const [newWorkflow, setNewWorkflow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUD, setAnchorElUD] = useState(null);
  const [loading, setLoading] = useState(false);
  const [caseTitle, setCaseTitle] = useState("");
  const [lawsuitNumber, setLawsuitNumber] = useState("");
  const [cases, setCases] = useState([]);
  const [editLawsuit, setEditLawsuit] = useState(false);
  const [caseId, setCaseId] = useState(0);
  const [caseDetails, setCaseDetails] = useState("");
  const [refresh, setRefresh] = useState(false);
  const open = Boolean(anchorEl);
  const openUD = Boolean(anchorElUD);

  let navigate = useNavigate();

  const renderActionButtons = (params) => {
    return <Button onClick={(e)=>
    {
      setCaseId(params.row.id)
      handleClickUD(e)
    }} variant="contained">Edit</Button>;
  };

  const renderEditLawsuitButton = (params) => {
    return <Button onClick={(e)=>{
      setCaseId(params.row.id)
      setCaseTitle(params.row.title)
      setLawsuitNumber(params.row.lawsuit_number)
      setEditLawsuit(true)
      handleClick(e)
    }} variant="contained">Edit</Button>;
  };
  const renderWorkflowLawsuitButton = (params) => {
    return <Button sx={{backgroundColor:"#f15f23", color:"#fff", fontWeight:"bold"}}onClick={(e)=>{
      setCaseId(params.row.id)
      setCaseTitle(params.row.title)
      setLawsuitNumber(params.row.lawsuit_number)
      setNewWorkflow(true);
      navigate(`/cases/${params.row.id}`);
    }} variant="contained"> start </Button>;
  };
  
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },    
   {
      field: "lawsuit_number",
      headerName: "lawsuit number",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    
    {
      field: "actions2",
      headerName: "Edit Lawsuit",
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderEditLawsuitButton(params),
    },
    {
      field: "actions3",
      headerName: "workflow",
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => renderWorkflowLawsuitButton(params),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const fetchData = async () => {
    //if (cases.length == 0) {
      GetCases().then(e=>
        {
          if(e)
          {
            setCases(e)
          }
          else
          {
            toast.error("could not load cases")
          }
        })
    //}
  }

  const handleClose = () => {
    setAnchorEl(null);
    setEditLawsuit(false)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUD = () => {
    setAnchorElUD(null);
  };

  const handleClickUD = (event) => {
    setAnchorElUD(event.currentTarget);
  };

  const onAddCaseClick = () => {
    handleClose();
    if(!editLawsuit)
    {
    AddCase(caseTitle, lawsuitNumber).then((e) => {
      if (e) {
          toast.success("case added successfully")
          fetchData()
      }
      else
      {
        toast.error("could not add the case")
      }
    });
  }
  else
  {
    EditCase(caseId,caseTitle,lawsuitNumber).then((e) => {
      if (e) {
          toast.success("case edited successfully")
          fetchData()
      }
      else
      {
        toast.error("could not edit the case")
      }
    });
  }
  setEditLawsuit(false)

    
  };

  const onUpdateCaseDetailsClick = () => {
    handleCloseUD();
    console.log("caseId")
    console.log(caseId)
    console.log(caseDetails)
    EditCaseDetails(caseId,caseDetails).then((e)=>
    {
      if (e) {
        toast.success("case details updated successfully")
        fetchData()
    }
    else
    {
      toast.error("could not update case details")
    }
    })

    
  };




  
  return  (
    <div style={{ height: "85%", width: "100%" }}>
      <div>
      <ToastContainer />
        <Button
          sx={{margin: 1, backgroundColor:"#f15f23", color:"#fff", fontWeight:"bold"}}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          ADD Case
        </Button>
        
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          container
          spacing={2}
          px={5}
          sx={{ marginBottom: 1, marginTop: 0.1 }}
        >
          <Grid item xs={12} sm={12}>
            <Typography color="primary" align="center" variant="h6">
              Please enter case details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={(v) => setCaseTitle(v.target.value)}
              label="Title"
              fullWidth
              value={caseTitle}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              onChange={(v) => setLawsuitNumber(v.target.value)}
              label="Lawsuit Number"
              fullWidth
              value={lawsuitNumber}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              onClick={() => onAddCaseClick()}
              fullWidth
            >
              {editLawsuit?"Edit Case":"Add Case"}
            </Button>
          </Grid>
          <Grid xs={12} sm={12} sx={{ marginY: 1, textAlign: "center" }}>
            {loading && <CircularProgress />}
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={openUD}
        onClose={handleCloseUD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          container
          spacing={2}
          px={5}
          sx={{ marginBottom: 1, marginTop: 0.1 }}
        >
          <Grid item xs={12} sm={12}>
            <Typography color="primary" align="center" variant="h6">
              Please enter case details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={(v) => setCaseDetails(v.target.value)}
              label="Details"
              fullWidth
              value={caseDetails}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              onClick={() => onUpdateCaseDetailsClick()}
              fullWidth
            >
              Update Details
            </Button>
          </Grid>
          <Grid xs={12} sm={12} sx={{ marginY: 1, textAlign: "center" }}>
            {loading && <CircularProgress />}
          </Grid>
        </Grid>
      </Dialog>

      <DataGrid
        rows={cases}
        columns={columns}
        disableSelectionOnClick
        loading={cases.length === 0}
      />
    </div>
  );
}
