import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Stack } from "@mui/material";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemIcon from "@mui/material/ListItemIcon";
import ProfilePage from "./ProfilePage";
import OrganizationsList from "./OrganizationsList";
import SubscriptionPage from "./SubscriptionPage";
import OrganisationUsersPage from "./OrganisationUsersPage";
import CasesPage from "./CasesPage";
import DashboardPage from "./DashboardPage"
import { Navigate } from "react-router-dom";
import { getRole, isAuth, unAuth, getName } from "../user/User";
import { useNavigate } from "react-router-dom";
import ClientsList from "./ClientsList";
import GetOrganization from "../api/GetOrganization";
import UploadOrgaImage from "../api/UploadOrgaImage";
import GroupIcon from "@mui/icons-material/Group";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import WorkIcon from "@mui/icons-material/Work";
import LogoutIcon from "@mui/icons-material/Logout";
import BusinessIcon from '@mui/icons-material/Business';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { convertBase64 } from "../helperFunctions/Converters";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainPage() {
  let navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [name, setName] = useState("");
  const [selected, setSelected] = React.useState("");
  const [sideMenuOptions, setSideMenuOptions] = useState(["Clients", "Cases"]);
  const [orgaImage, setOrgaImage] = useState(0);
  const handleDrawerOpen = () => {
    setOpen(true);
  };



  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setName(getName());
    let role = getRole();
    if (role == "admin") {
      setSideMenuOptions(["Dashboard", "Organizations", "System Variables"]);
      setSelected("Dashboard");
    } else {
      if (role == "organization") {
        setSideMenuOptions([
          "Users",
          "Subscription",
          "Clients",
          "Cases",
          
        ]);
        setSelected("Cases");
        let response = GetOrganization().then((r) => {
          if (r) {
            setOrgaImage(r.image);
          }
        });
      } else {
        if (role == "user") {
          setSideMenuOptions(["Clients", "Cases"]);
          setSelected("Clients");
        }
      }
    }
  }, []);

  const RenderPage = (props) => {
    if (props.selected == "Users")
      return <OrganisationUsersPage></OrganisationUsersPage>;
    else if (props.selected == "Dashboard")
      return <DashboardPage sx={{width:"100%"}}/>;
    else {
      if (props.selected == "Subscription")
        return <SubscriptionPage></SubscriptionPage>;
      else if (props.selected == "Organizations")
        return <OrganizationsList></OrganizationsList>;
      else if (props.selected == "Cases") return <CasesPage ></CasesPage>;
      else if (
        props.selected == "Edit your organization profile" ||
        props.selected == "Edit your user profile"
      )
        return <ProfilePage></ProfilePage>;
      else if (props.selected == "Clients") return <ClientsList></ClientsList>;
    }
  };

  const myRefname = useRef(null);

  const RenderName = () => {
    let role = getRole();
    if (role == "organization") {
      return (
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <img
            src={require("../images/logo.png")}
            style={{
              alignSelf: "center",
              width: "30%",
              position: "absolute",
              top: 0,
            }}
          ></img>

          <img
            onClick={() => {
              myRefname.current.click();
            }}
            src={
              orgaImage.length > 10
                ? orgaImage
                : require("../images/uploadimageicon.png")
            }
            style={{
              width: "50%",
              borderRadius: "50%",
              height: undefined,
              aspectRatio: 1,
            }}
          ></img>
          <Button sx={{ display: "none" }} component="label" ref={myRefname}>
            <input
              hidden
              type={"file"}
              id={"mediaFile"}
              onChange={(e) => {
                const file = e.target.files[0];
                convertBase64(file)
                  .then((e) => {
                    console.log(e);
                    UploadOrgaImage(e).then(e=>window.location.reload(false));
                  })
                  .then((e) => console.log(e));
              }}
            />
          </Button>
          <Button variant="text" sx={{ padding: 0, margin: 0, fontSize: 10 }}>
            Organization
          </Button>
          <Button
            endIcon={<EditIcon />}
            variant="text"
            sx={{ height: "15%", fontSize: 10 }}
            onClick={() => {
              setSelected("Edit your organization profile");
            }}
          >
            {name}
          </Button>
        </Stack>
      );
    } else {
      if (role == "admin") {
        return (
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <img
              src={require("../images/logo.png")}
              style={{
                alignSelf: "center",
                width: "30%",
                position: "absolute",
                top: 0,
              }}
            ></img>
            <Button variant="text" sx={{ padding: 0, margin: 0, fontSize: 10 }}>
              Admin
            </Button>
            <Button variant="text" sx={{ height: "15%", fontSize: 10 }}>
              {name}
            </Button>
          </Stack>
        );
      } else
        return (
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <Avatar
              src="../images/uploadimageicon.png"
              sx={{ width: 100, height: 100 }}
            />
            <Button variant="text" sx={{ padding: 0, margin: 0, fontSize: 10 }}>
              User
            </Button>
            <Button
              endIcon={<EditIcon />}
              variant="text"
              sx={{ height: "15%", fontSize: 10 }}
              onClick={() => {
                setSelected("Edit your user profile");
              }}
            >
              {name}
            </Button>
          </Stack>
        );
    }
  };

  

  const ListIcon = (props) => {
    if (props.text == "Dashboard")
      return (
        <ListItemIcon>
          <DashboardIcon></DashboardIcon>
        </ListItemIcon>
      );
    else if (props.text == "Users") {
      return (
        <ListItemIcon>
          <GroupIcon></GroupIcon>
        </ListItemIcon>
      );
    } else {
      if (props.text == "Subscription") {
        return (
          <ListItemIcon>
            <AppRegistrationIcon></AppRegistrationIcon>
          </ListItemIcon>
        );
      } else {
        if (props.text == "Clients") {
          return (
            <ListItemIcon>
              <SupervisedUserCircleIcon></SupervisedUserCircleIcon>
            </ListItemIcon>
          );
        } else {
          if (props.text == "Cases") {
            return (
              <ListItemIcon>
                <WorkIcon></WorkIcon>
              </ListItemIcon>
            );
          }
          else
          {
            if (props.text == "Logout") {
              return (
                <ListItemIcon>
                  <LogoutIcon sx={{color:"#ff0000"}}></LogoutIcon>
                </ListItemIcon>
              );
            }
            else{
              if (props.text == "Organizations") {
                return (
                  <ListItemIcon>
                    <BusinessIcon></BusinessIcon>
                  </ListItemIcon>
                );
              }
              else{
                if (props.text == "System Variables") {
                return (
                  <ListItemIcon>
                    <SettingsSuggestIcon></SettingsSuggestIcon>
                  </ListItemIcon>
                );
              }
              }
            }
          }
        }
      }
    }

    return null;
  };

  if (isAuth()) {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            {/*<Typography variant="h6" noWrap component="div">
              Lawyeroid
    </Typography>*/}
            <Typography
              sx={{ flexGrow: 1, textAlign: "center" }}
              variant="h6"
              component="div"
            >
              {selected}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <RenderName></RenderName>

          <Divider />

          <List>
            {sideMenuOptions.map((text, index) => (
              <ListItem
                onClick={() => {
                  setSelected(text);
                }}
                button
                key={text}
                style={selected === text ? { backgroundColor: "#d3d3d3" } : {}}
              >
                <ListIcon text={text}> </ListIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
            <ListItem
              onClick={() => {
                unAuth();
                navigate("/signin");
              }}
              button
              key={"Logout"}
            >
              <ListIcon text={"Logout"}> </ListIcon>
              <ListItemText
                primary={
                  <Typography type="body2" style={{ color: "#FF0000" }}>
                    Logout
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Drawer>
        <Main
          open={open}
          className="App-header"
          style={{ backgroundImage: `url("https://lawyeroid.ai/img/fullImages/pic2.jpg")` ,backgroundPosition:'center',backgroundSize:'cover'}}
        >
          {/* <DrawerHeader /> */}
          <RenderPage selected={selected}></RenderPage>
        </Main>
      </Box>
    );
  } else {
    return <Navigate to="signin" replace={true}></Navigate>;
  }
}
