import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function CheckClientExists(clientNames) {
  var response;
  var myHeaders = new Headers();
  
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer "+getToken());
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(clientNames);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: raw
  };
  response = await fetch(SERVER_URL + "api/clients/exists/name", requestOptions);
  if (response.status === 200) return response.json();
  return false;
}
