import { SERVER_URL } from "../constants";
import { getToken } from "../user/User";

export default async function AddCase(
    title,
    lawsuit_number
) {
  var response;

  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + getToken());
  myHeaders.append("Content-Type", "application/json");
var raw = JSON.stringify({
  "title": title,
  "lawsuit_number": lawsuit_number
});

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  response = await fetch(SERVER_URL + "api/lawsuits", requestOptions);
  if (response.status === 200 || response.status === 201)
    return response.json();
  return false;
}