import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import sideMenuOptions from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";

import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, ListItemIcon, Stack } from "@mui/material";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { listHasRole } from '../../Sidelist'
import { getRole, isAuth, unAuth, getName } from "../../user/User";
import { useNavigate } from "react-router-dom";
import UploadOrgaImage from "../../api/UploadOrgaImage";

import { convertBase64 } from "../../helperFunctions/Converters";
import GetOrganization from "../../api/GetOrganization";

function Sidebar({ toggleOpen, open, setActive, active }) {
  const navigate = useNavigate()
  const drawerWidth = 240;
  const [orgaImage, setOrgaImage] = useState(0);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));
  const theme = useTheme();
  let role = getRole();
  const list = listHasRole(role)
  const myRefname = useRef(null);
  const name = getName()
  const GetOrganizationData=()=>{
    let response = GetOrganization().then((r) => {
      if (r) {
        setOrgaImage(r.image);
      }
    });
  }
  useEffect(() => {
    if (role == "organization") {
      GetOrganizationData()
    }

  }, [])
  const RenderName = () => {
    let role = getRole();
    if (role == "organization") {
      return (
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <img
            src={require("../../images/logo.png")}
            style={{
              alignSelf: "center",
              width: "30%",
              position: "absolute",
              top: 0,
            }}
          ></img>

          <img
            onClick={() => {
              myRefname.current.click();
            }}
            src={
              orgaImage.length > 10
                ? orgaImage
                : require("../../images/uploadimageicon.png")
            }
            style={{
              width: "50%",
              borderRadius: "50%",
              height: undefined,
              aspectRatio: 1,
            }}
          ></img>
          <Button sx={{ display: "none" }} component="label" ref={myRefname}>
            <input
              hidden
              type={"file"}
              id={"mediaFile"}
              onChange={(e) => {
                const file = e.target.files[0];
                convertBase64(file)
                  .then((e) => {
                    console.log(e);
                    UploadOrgaImage(e).then(GetOrganizationData);
                  })
                  .then((e) => console.log(e));
              }}
            />
          </Button>
          <Button variant="text" sx={{ padding: 0, margin: 0, fontSize: 10 }}>
            Organization
          </Button>
          <Button
            endIcon={<EditIcon />}
            variant="text"
            sx={{ height: "15%", fontSize: 10 }}
            onClick={() => {
              navigate('/profile')
              setActive("Edit your organization profile");
            }}
          >
            {name}
          </Button>
        </Stack>
      );
    } else {
      if (role == "admin") {
        return (
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <img
              src={require("../../images/logo.png")}
              style={{
                alignSelf: "center",
                width: "30%",
                position: "absolute",
                top: 0,
              }}
            ></img>
            <Button variant="text" sx={{ padding: 0, margin: 0, fontSize: 10 }}>
              Admin
            </Button>
            <Button variant="text" sx={{ height: "15%", fontSize: 10 }}>
              {name}
            </Button>
          </Stack>
        );
      } else
        return (
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <Avatar
              src="../images/uploadimageicon.png"
              sx={{ width: 100, height: 100 }}
            />
            <Button variant="text" sx={{ padding: 0, margin: 0, fontSize: 10 }}>
              User
            </Button>
            <Button
              endIcon={<EditIcon />}
              variant="text"
              sx={{ height: "15%", fontSize: 10 }}
              onClick={() => {
                navigate('/profile')
                setActive("Edit your user profile");
              }}
            >
              {name}
            </Button>
          </Stack>
        );
    }
  };
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={toggleOpen}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <RenderName></RenderName>

      <Divider />

      <List>
        {
          list.map(item => (

            <ListItem
              key={item.name}
              onClick={() => {
                setActive(item.name)
                navigate(item.link)
              }}
              button

              style={active === item.name ? { backgroundColor: "#d3d3d3" } : {}}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>



          ))
        }

        <ListItem
          onClick={() => {
            unAuth();
            navigate("/signin");
          }}
          button
          key={"Logout"}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: "#ff0000" }}></LogoutIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography type="body2" style={{ color: "#FF0000" }}>
                Logout
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Drawer>

  )
}

export default Sidebar