import { TextField } from "@mui/material";
import { useState } from "react";
import { validateEmail } from "../helperFunctions/Validation";
export default function EmailTextField(props)
{

    const [emailVal, setEmailVal] = useState(true);
    
    return (
        <TextField
                  onChange={(v) => {
                    setEmailVal(validateEmail(v.target.value))
                    props.onChange(v.target.value)
                        
                    }}
                    label="Email"
                  fullWidth
                  error={!emailVal}
                ></TextField>
    )
}